import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import 'css/pages/hero.css';
import { get_data, StandardForm, TextField, useForm, v } from '@xmlleads/form-magic';

export default function Hero() {
    const [switchToggled, setSwitchToggled] = useState(false);
    const [loan, setLoan] = useState(0);
    const smallButton =
        'cursor-pointer px-4 xs:px-6 md:px-16 lg:px-8 xl:px-12 py-3 bg-main-700 text-white text-md font-medium rounded-md shadow-xl border border-transparent transition ease-in-out delay-120 hover:bg-white duration-300 hover:text-main-700 hover:border-3 hover:border-main-700 hover:transition-color';
    const largeButton =
        'cursor-pointer w-full py-3 bg-main-700 text-white text-md font-medium rounded-md shadow-xl border border-transparent transition ease-in-out delay-120 hover:bg-white duration-300 hover:text-main-700 hover:border-3 hover:border-main-700 hover:transition-color';
    const [heroApplyNowForm, updateHeroApplyNowForm] = useForm({});
    const submitHeroSchema = {
        email: v.required,
        loan: v.optional,
    };
    const submitHeroApplyNowForm = async () => {
        const data = get_data(heroApplyNowForm);
        const errors = {};
        const keysWithErrors = [];
        Object.keys(submitHeroSchema).forEach((key) => {
            if (typeof submitHeroSchema[key] !== 'function') {
                console.warn(`Validation function for key "${key}" is not defined or is not a function.`);
                return;
            }
            const validationResult = submitHeroSchema[key](data[key]);
            if (validationResult) {
                keysWithErrors.push(key);
                errors[key] = validationResult;
            }
        });
        if (keysWithErrors.length > 0) {
            updateHeroApplyNowForm({ key: keysWithErrors, error: errors, type: 'show_error' });
            return;
        }
        const queryParams = new URLSearchParams(data).toString();
        window.location.href = `/apply-now?${queryParams}`;
    };

    function clickHandler(e) {
        e.preventDefault();
        setLoan(e.target.value);
        switchToggled ? setSwitchToggled(false) : setSwitchToggled(true);
    }

    // eslint-disable-next-line no-return-assign
    return (
        <div
            id="hero"
            className="relative w-full h-full bg-gray-500 bg-blend-multiply md:bg-no-repeat md:bg-center lg:bg-cover lg:bg-blend-normal lg:bg-left xl:bg-left-top 2xl:bg-center"
        >
            <div className="relative w-full h-64 hidden sm:h-72 lg:block lg:h-96 lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 lg:h-full">
                <div
                    className="absolute inset-0 bg-gray-500 mix-blend-multiply"
                    aria-hidden="true"
                />
            </div>
            <div className="relative w-full h-64 hidden sm:h-72 md:h-96 lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                <div
                    className="absolute inset-0 bg-gray-500 mix-blend-multiply"
                    aria-hidden="true"
                />
            </div>
            <main className="lg:relative font-inter">
                <div className="flex justify-center mx-auto max-w-7xl w-full pt-16 pb-10 text-center lg:pt-35 lg:pb-33 lg:text-left">
                    <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                        <h1
                            id="title"
                            className="text-4xl text-center tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl"
                        >
                            <span className="block xl:inline">
                                Here for you{' '}
                            </span>
                            <span className="block text-highlight-200 xl:inline font-inter">
                                in your time of need.
                            </span>
                        </h1>
                        <p
                            id="leading"
                            className="mt-3 max-w-md mx-auto text-lg text-center text-gray-300 sm:text-xl md:mt-5 md:max-w-3xl"
                        >
                            Get up to $1,500 by applying today! *
                        </p>
                        <div className="w-full h-50 p-5 md:h-96 mt-8 bg-white rounded-3xl md:p-10 lg:p-8 xl:p-10 shadow-2xl font-inter">
                            {!switchToggled && (
                                <Fragment>
                                    <div>
                                        <p className="text-center text-xl text-gray-500 font-semibold pb-3 font-inter">
                                            Get The Right Loan That You Need
                                            Today!
                                        </p>
                                    </div>
                                    <div className="flex justify-between py-2">
                                        <input
                                            type="button"
                                            value="$100"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                        <input
                                            type="button"
                                            value="$200"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                        <input
                                            type="button"
                                            value="$300"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                    </div>
                                    <div className="flex justify-between py-2">
                                        <input
                                            type="button"
                                            value="$400"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                        <input
                                            type="button"
                                            value="$500"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                        <input
                                            type="button"
                                            value="$600"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                    </div>
                                    <div className="flex justify-between py-2">
                                        <input
                                            type="button"
                                            value="$700"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                        <input
                                            type="button"
                                            value="$800"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                        <input
                                            type="button"
                                            value="$900"
                                            className={smallButton}
                                            onClick={clickHandler}
                                        />
                                    </div>
                                    <div className="py-2">
                                        <input
                                            type="button"
                                            value="$1000"
                                            className={largeButton}
                                            onClick={clickHandler}
                                        />
                                    </div>
                                </Fragment>
                            )}
                            {switchToggled && (
                                <Fragment>
                                    <div className="py-2">
                                        <p className="text-center text-xl text-gray-500 font-semibold pb-3 font-inter">
                                            Get the Right Loan That You Need
                                            Today!
                                        </p>
                                    </div>
                                    <div>
                                        <StandardForm
                                            custom_submit_label="Apply Now"
                                            form={heroApplyNowForm}
                                            submit={submitHeroApplyNowForm}
                                            schema={{}}
                                            update={updateHeroApplyNowForm}
                                            show_application_error={() => {
                                            }}
                                            className="pt-5 md:pt-10 sm:max-w-xl sm:mx-auto lg:mx-0"
                                            customSubmitClass="mt-2 mb-10 md:mt-5 w-full py-3 rounded-md shadow-sm bg-main-700 text-white text-lg font-medium border border-transparent transition ease-in-out delay-120 hover:border-3 hover:border-main-700 hover:text-main-700 hover:bg-white duration-300"
                                        >
                                            <div>
                                                <TextField
                                                    name="email"
                                                    label=""
                                                    placeholder="Email address"
                                                    input_class="w-full px-4 py-3 shadow-sm rounded-md text-base text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900 border border-gray-300 rounded-md"
                                                />
                                                <TextField
                                                    name="loan"
                                                    label=""
                                                    type="hidden"
                                                    value={loan}
                                                    input_class="hidden"
                                                />
                                            </div>
                                        </StandardForm>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                        <p
                            id="disclosure"
                            className="mt-8 text-sm text-gray-400 sm:mt-8 font-inter"
                        >
                            * Completion of our installment loan application in
                            no way guarantees that you will be approved for an
                            installment loan offer. The maximum loan amount for
                            first-time customers is $1,400.00.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
}
