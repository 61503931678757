import { h } from 'preact';
import { get_data, StandardForm, TextField, useForm, v } from '@xmlleads/form-magic';

export default function CTA() {
  const [ctaApplyNowForm, updateCtaApplyNowForm] = useForm({});
  const submitCtaSchema = {
    email: v.required,
    loan: v.optional,
  };
  const submitCtaApplyNowForm = async () => {
    const data = get_data(ctaApplyNowForm);
    const errors = {};
    const keysWithErrors = [];
    Object.keys(submitCtaSchema).forEach((key) => {
      if (typeof submitCtaSchema[key] !== 'function') {
        console.warn(`Validation function for key "${key}" is not defined or is not a function.`);
        return;
      }
      const validationResult = submitCtaSchema[key](data[key]);
      if (validationResult) {
        keysWithErrors.push(key);
        errors[key] = validationResult;
      }
    });
    if (keysWithErrors.length > 0) {
      updateCtaApplyNowForm({ key: keysWithErrors, error: errors, type: 'show_error' });
      return;
    }
    const queryParams = new URLSearchParams(data).toString();
    window.location.href = `/apply-now?${queryParams}`;
  };

  return (
    <div className="bg-white font-inter">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Ready to Start?</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                Anything can happen. With a click of a button, <span className="text-white">Crane Finance</span> is committed to keeping you financially covered.
              </p>
              <StandardForm
                  custom_submit_label="Apply Now"
                  form={ctaApplyNowForm}
                  submit={submitCtaApplyNowForm}
                  schema={{}}
                  update={updateCtaApplyNowForm}
                  show_application_error={() => {
                  }}
                  className="mt-12 sm:mx-auto sm:max-w-lg sm:flex"
                  customSubmitClass="mt-2 md:mt-0 block w-full rounded-md border border-transparent px-5 py-3 bg-main-700 text-base font-medium text-white shadow transition ease-in-out delay-120 hover:bg-white duration-300 hover:text-main-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                  customSubmitContainerClassName="mt-4 md:mt-0 md:ml-4 flex-1">
                <div className="min-w-0 flex-1">
                  <TextField
                      name="email"
                      type="email"
                      label=""
                      placeholder="Email address"
                      input_class="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                      required />
                </div>
              </StandardForm>
            </div>
          </div>
          <div className="w-full h-full bg-gray-400">
            <img
              className="w-full h-full object-cover mix-blend-multiply"
              src="/public/images/cta.jpg"
              alt="Family Start"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
