import type { CustomFlowbiteTheme } from 'flowbite-react';

const flowbiteTheme: CustomFlowbiteTheme = {
    navbar: {
        link: {
            base: 'relative inline-flex items-center justify-left rounded-md p-2 focus:outline-none w-full ' +
                // Begin portfolio customizations
                'text-white focus:ring-2 focus:ring-inset focus:ring-white text-white transition-colors duration-300 ease-in-out hover:bg-gray-800 md:hover:bg-main-700',
            active: {
                on: '',
                off: '',
            },
        },
        toggle: {
            base: 'relative inline-flex items-center rounded-lg p-2 text-sm focus:outline-none ' +
                'focus:ring-2 focus:ring-inset focus:ring-white md:hidden ' +
                // Begin portfolio customizations
                'text-white hover:bg-main-700',
            icon: 'h-6 w-6 shrink-0',
        },
        collapse: {
            base: 'w-full md:block md:w-auto' +
                // Begin portfolio customizations
                '',
            list: 'mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 font-medium md:text-sm md:font-medium block rounded-md px-3 py-2 text-base ' +
                // Begin portfolio customizations
                'bg-main-700 md:bg-gray-800',
            hidden: {
                on: 'hidden',
                off: '',
            },
        },
    },
};

export default flowbiteTheme;
