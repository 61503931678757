import { h, render, Fragment } from "preact";

export default function Featured() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:pt-30 lg:pb-35 font-inter">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-4xl font-semibold tracking-wider text-main-700">Easy to Borrow, Easy to Lend.</h2>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">From the comfort of your home or when you're on the go, <span className="font-semibold text-main-700">Crane Finance</span> has got you covered to meet your short-term financial needs.</p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="pt-6">
              <div className="flow-root rounded-lg bg-white px-6 pb-10">
                <div className="-mt-6">
                  <div>
                      {/* <!-- Heroicon name: outline/refresh --> */}
                      <img
                        loading="lazy"
                        decoding="async"
                        className="h-22 w-22 inline-flex items-center justify-center"
                        src="/public/images/document1.gif"
                        alt="Document Gif"
                      />
                  </div>
                  <h3 className="mt-6 text-xl font-medium tracking-tight text-main-700">Quick Application</h3>
                  <p className="mt-5 text-base text-gray-500">Our online application is <span className="text-main-700">fast and easy</span> to complete. It's quite literally a click away!</p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root rounded-lg bg-white px-6 pb-10">
                <div className="-mt-6">
                  <div>
                      {/* <!-- Heroicon name: outline/refresh --> */}
                      <img
                        loading="lazy"
                        decoding="async"
                        className="h-22 w-22 inline-flex items-center justify-center"
                        src="/public/images/money-bag1.gif"
                        alt="Cash Gif"
                      />
                  </div>
                  <h3 className="mt-6 text-xl font-medium tracking-tight text-main-700">*Same Day Funding</h3>
                  <p className="mt-5 text-base text-gray-500">Funds can typically be deposited the same <span className="text-main-700">business day</span> your loan is approved.</p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root rounded-lg bg-white px-6 pb-10">
                <div className="-mt-6">
                  <div>
                      {/* <!-- Heroicon name: outline/refresh --> */}
                      <img
                        loading="lazy"
                        decoding="async"
                        className="h-22 w-22 inline-flex items-center justify-center"
                        src="/public/images/padlock1.gif"
                        alt="Security Gif"
                      />
                  </div>
                  <h3 className="mt-6 text-xl font-medium tracking-tight text-main-700">Safe Payments</h3>
                  <p className="mt-5 text-base text-gray-500">We make sure to keep your information <span className="text-main-700">protected.</span></p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root rounded-lg bg-white px-6 pb-10">
                <div className="-mt-6">
                  <div>
                      {/* <!-- Heroicon name: outline/refresh --> */}
                      <img
                        loading="lazy"
                        decoding="async"
                        className="h-22 w-22 inline-flex items-center justify-center"
                        src="/public/images/support1.gif"
                        alt="Support Gif"
                      />
                  </div>
                  <h3 className="mt-6 text-xl font-medium tracking-tight text-main-700">Customer Care</h3>
                  <p className="mt-5 text-base text-gray-500">Our <span className="text-main-700">friendly and welcoming</span> Customer Service Representatives are here for you.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
          <a href="/apply-now" className="mt-14 whitespace-nowrap inline-flex items-center justify-center font-inter px-10 py-4 border border-transparent rounded-lg shadow-md text-base font-medium text-md text-white bg-main-700 transition ease-in-out delay-120 hover:bg-white duration-300 hover:text-main-700 hover:border-3 hover:border-main-700">Get Started</a>
      </div>
    </div>
  );
}
