import { h } from 'preact';
import useAuth from 'js/components/User/useAuth.js';
import { Flowbite, Navbar } from 'flowbite-react';
import flowbiteTheme from '../../themes/flowbite_theme';
import logo from 'img/Crane Finance Logo.png';

const navigation = [
    { name: 'Contact', href: '/contact-us' },
    { name: 'Apply Now', href: '/apply-now' },
    { name: 'Sign In', href: '/login' },
];

const navigation_authenticated = [
    { name: 'My Account', href: '/account/#' },
    { name: 'Loan Overview', href: '/account/#loan' },
    { name: 'Payment Options', href: '/account/#payment' },
    { name: 'My Profile', href: '/account/#profile' },
    { name: 'Loan History', href: '/account/#history' },
    { name: 'Contact', href: '/contact-us' },
];

export default function Header() {
    const auth = useAuth();

    return (
        <Flowbite theme={{ mode: 'light', theme: flowbiteTheme }}>
            <div className="bg-gray-800 sm:font-inter py-4">
                <Navbar className="bg-transparent mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            className="h-14 w-auto"
                            alt="Crane Finance Logo"
                        />
                    </Navbar.Brand>
                    <div
                        className="md:hidden w-auto"
                    >
                        &nbsp;
                    </div>
                    <Navbar.Toggle />
                    {(auth.loading || !auth.user) && (
                        <Navbar.Collapse>
                            <Navbar.Link
                                key="Contact"
                                href="/contact-us"
                                className="flex justify-center items-center"
                            >
                                Contact
                            </Navbar.Link>
                            <Navbar.Link
                                key="Apply Now"
                                href="/apply-now"
                                className="flex justify-center items-center"
                            >
                                Apply Now
                            </Navbar.Link>
                            <div className="mb-4 md:hidden md:mb-0" />
                            <Navbar.Link
                                key="Sign In"
                                href="/login"
                                className="flex justify-center items-center border-2 border-solid border-white px-6 transition-colors duration-300 ease-in-out hover:border-main-700"
                            >
                                Sign In
                            </Navbar.Link>
                        </Navbar.Collapse>
                    )}
                    {!auth.loading && auth.user && (
                        <Navbar.Collapse>
                            {navigation_authenticated.map((item) => (
                                <Navbar.Link
                                    key={item.name}
                                    href={item.href}
                                    className="flex justify-center items-center"
                                >
                                    {item.name}
                                </Navbar.Link>
                            ))}
                            <div className="mb-4 md:hidden md:mb-0" />
                            <Navbar.Link
                                href="#"
                                className="flex justify-center items-center border-2 border-solid border-white px-6 transition-colors duration-300 ease-in-out hover:border-main-700"
                                onClick={() => {
                                    auth.sign_out(() => {
                                        window.location = '/';
                                    });
                                }}

                            >
                                Log Out
                            </Navbar.Link>
                        </Navbar.Collapse>
                    )}
                </Navbar>
            </div>
        </Flowbite>
    );
}
